import {
  computed,
  nextTick,
  ref,
  provide,
  inject,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { fetchPageBuilder } from '@drapejs/core';
import { search } from '@distancify/drapejs-litium';
import { useGA4 } from '@distancify-storefront/tracking-gtm';

import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import { registerModalKey, unregisterModalKey } from '@/composables/useModals';
import { isSearchOpenKey } from '@/keys';
import useCustomerPrices from './useCustomerPrices';


export interface ISearchResults {
  pageSearch: {
    take: number;
    hits: number;
    totalHits: number;
    searchPhrase: string;
    stories: any[];
    pages: any[];
  };
  productSearchV2: {
    facetSuggestions: IFacetSuggestion[];
    hits: number;
    products: any[];
  };
  searchString: string;
  storySearch: {
    take: number;
    hits: number;
    totalHits: number;
    searchPhrase: string;
    stories: any[];
  };
}

export interface IFacetSuggestion {
  field: string;
  suggestedValues: {
    value: string;
    label: string;
  }[];
}

export default function () {
  const { websiteText } = useWebsiteTexts();
  const { navigate, mitt, route, channel, invoke } = useContext();
  const { fetchPricesForProducts } = useCustomerPrices();
  
  const ga4 = useGA4();

  const searchResult = ref<any>();
  provide('searchResult', searchResult);

  const quickSearchProductCount = 20;
  const width = ref(0);
  const searchString = ref('');
  provide('searchString', searchString);

  const lastSearchString = ref('');
  const isMounted = ref(false);

  const registerModal = inject(registerModalKey, (key: string) => {});
  const unregisterModal = inject(unregisterModalKey, (key: string) => {});
  const isSearchOpen = inject(isSearchOpenKey, ref<boolean>(false));

  const rootCategory = computed(() => {
    return channel.value?.rootProductsCategory || {};
  });

  const isDesktop = computed(() => {
    return width.value > 768;
  });

  const channelRootPath = computed(() => {
    return channel.value?.rootPath || '';
  });

  const products = computed(() => {
    return searchResult.value?.productSearchV2?.products || [];
  });
  provide('products', products);

  const productHits = computed(() => {
    return searchResult.value?.productSearchV2?.hits || 0;
  });
  provide('productHits', productHits);

  const pages = computed(() => {
    return searchResult.value?.pageSearch?.pages || [];
  });
  provide('pages', pages);

  const pageHits = computed(() => {
    return searchResult.value?.pageSearch?.hits || 0;
  });
  provide('pageHits', pageHits);

  const facetSuggestions = computed(() => {
    return searchResult.value?.productSearchV2?.facetSuggestions || [];
  });
  provide('facetSuggestions', facetSuggestions);

  const productHitsLabel = computed(() => {
    return websiteText('search__see_all', {
      hits: productHits.value,
    }).value;
  });
  provide('productHitsLabel', productHitsLabel);

  const hasAnySearchResults = computed(() => {
    return (
      products.value.length > 0 ||
      facetSuggestions.value.length > 0 ||
      pages.value.length > 0
    );
  });
  provide('hasAnySearchResults', hasAnySearchResults);

  watch(
    route,
    () => {
      if (isSearchOpen.value) {
        pushSearchEvent();
      }
      closeSearch();
    },
    {
      deep: true,
    }
  );

  watch(isSearchOpen, (curr) => {
    if (curr) {
      registerModal('SearchView');
    }
    if (!curr) {
      unregisterModal('SearchView');
    }
  });

  onMounted(() => {
    isMounted.value = true;

    mitt.on('perform-quick-search', performQuickSearch);
    mitt.on('apply-facet-suggestion', applyFacetSuggestion);
    mitt.on('close-search', closeSearch);
    mitt.on('open-search-view', openSearchView);

    mitt.on('search-performed', pushSearchEvent);
    mitt.on('search-result-clicked', pushSearchEvent);

    setWidth();
    window.addEventListener('resize', setWidth);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', setWidth);
    mitt.off('perform-quick-search', performQuickSearch);
    mitt.off('apply-facet-suggestion', applyFacetSuggestion);
    mitt.off('close-search', closeSearch);
    mitt.off('open-search-view', openSearchView);

    mitt.off('search-performed', pushSearchEvent);
    mitt.off('search-result-clicked', pushSearchEvent);
  });

  function openSearchView() {
    searchResult.value = null;
    isSearchOpen.value = true;
  }

  async function applyFacetSuggestion(facet: {
    facetId: string;
    facetValueId: string;
  }) {
    if (!rootCategory.value?.url) {
      return;
    }
    const query = {
      facets: `${facet.facetId}:${facet.facetValueId}`,
    };

    mitt.emit('clear-search-input');

    await goToProductsRootPage(query);
  }

  async function performQuickSearch(newSearchString: string) {
    searchString.value = newSearchString;

    if (searchString.value === '') {
      searchResult.value = null;
      return;
    }

    if (!rootCategory.value.id) {
      return;
    }

    const request: any = fetchPageBuilder(
      route.protocol,
      route.host,
      route.pathname,
      {
        ...route.query,
        search: searchString.value,
      },
      ''
    );

    request.take = quickSearchProductCount;
    request.categorySystemId = rootCategory.value.id;

    const newSearchResult = await invoke(
      search.commands.performQuickSearch,
      request
    );

    if (newSearchResult.storySearch) {
      const stories = (newSearchResult.storySearch.stories || []).map(
        (s: any) => {
          const c = { ...s };
          const url = s?.localizedSlug || s?.slug;
          if (url && channelRootPath.value) {
            c.url = `${channelRootPath.value}${url}`;
          }
          return c;
        }
      );

      newSearchResult.pageSearch = {
        ...newSearchResult.storySearch,
        pages: stories || [],
      };
    }

    if (newSearchResult.productSearchV2?.products) {
        fetchPricesForProducts(newSearchResult.productSearchV2?.products.map((v: any) => v.selectedVariant?.id));
    }

    if (searchString.value !== '') {
      searchResult.value = newSearchResult;
      searchResult.value.searchString = searchString.value;

      isSearchOpen.value = true;
    }
  }

  async function performFullSearch() {
    nextTick(() => {
      mitt.emit('close-search');
    });
    if (!rootCategory.value?.url) {
      return;
    }

    const query = { ...route.query };
    delete query.search;

    if (searchString.value) {
      query.search = searchString.value;
    }
    mitt.emit('clear-search-input');

    await goToProductsRootPage(query);
  }
  provide('performFullSearch', performFullSearch);

  async function goToProductsRootPage(query: any) {
    const querySegments: any[] = [];
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value) {
        querySegments.push(`${key}=${encodeURIComponent(value)}`);
      }
    });

    const queryString =
      querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
    await navigate(`${rootCategory.value.url}${queryString}`);
  }

  function setWidth() {
    width.value = window.innerWidth;
  }

  function closeSearch() {
    isSearchOpen.value = false;
    searchString.value = '';
  }

  function pushSearchEvent() {
    if (
      lastSearchString.value === searchString.value ||
      searchString.value === ''
    ) {
      return;
    }

    ga4.search(searchString.value);

    lastSearchString.value = searchString.value;
  }

  return {
    isMounted,
    isDesktop,
    isSearchOpen,
    products,
    productHits,
    pages,
    pageHits,
    facetSuggestions,
    searchString,
    productHitsLabel,
    hasAnySearchResults,
    performFullSearch,
  };
}

export function getSearchContext() {
  return {
    products: inject(
      'products',
      computed<any>(() => [])
    ),
    hasAnySearchResults: inject(
      'hasAnySearchResults',
      computed(() => false)
    ),
    facetSuggestions: inject(
      'facetSuggestions',
      computed<IFacetSuggestion[]>(() => [])
    ),
    pageHits: inject(
      'pageHits',
      computed(() => 0)
    ),
    productHits: inject(
      'productHits',
      computed(() => 0)
    ),
    productHitsLabel: inject(
      'productHitsLabel',
      computed(() => '')
    ),
    performFullSearch: inject('performFullSearch', () => Promise<void>),
    pages: inject(
      'pages',
      computed<any>(() => [])
    ),
    searchResult: inject('searchResult', ref()),
  };
}
