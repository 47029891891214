<script>
export default {
  emits: ["open"],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    open() {
      this.$emit("open", this.order);
    },
  },
};
</script>

<template>
  <div class="my-pages-order">
    <div class="my-pages-order__header">
      <div>
        <div class="my-pages-order__date">
          {{ $globalTexts.mypages__order_list_date_label || 'mypages__order_list_date_label' }} {{ order.date }}
        </div>
        <div class="my-pages-order__number">
          {{ $globalTexts.mypages__order_list_number_label || 'mypages__order_list_number_label' }} {{ order.number }}
        </div>
      </div>

      <button
        class="
          secondary-button secondary-button--inverted
          my-pages-order__button
        "
        @click="open"
      >
        {{ $globalTexts.mypages__order_list_open_label || 'mypages__order_list_open_label' }}
      </button>
    </div>

    <div class="my-pages-order__total">
      <span>{{ order.formattedAmount }}</span>
      <span>{{ $globalTexts.mypages__order_list_total_label || 'mypages__order_list_total_label' }}</span>
    </div>
  </div>
</template>

<style>
.my-pages-order {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-00);
  border-radius: 3px;
  border: 1px solid var(--color-neutral-20);
}

.my-pages-order__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1.5px solid var(--color-neutral-03);
  padding: 10px 14px;
}

.my-pages-order__date {
  color: var(--color-cloudy-gray);
  font-size: 13px;
  line-height: 21px;
}

.my-pages-order__number {
  color: var(--color-text-black);
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}

.my-pages-order__button.secondary-button {
  padding: 13px 27px;
}

.my-pages-order__total {
  font-weight: bold;
  font-size: var(--size-xs);
  line-height: 16px;
  color: var(--color-intense-black);
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
}
</style>
