<script>
import { fetchPageBuilder, OptionsComponent, navigateKey } from '@drapejs/core';
import MyPagesBack from './components/MyPagesBack.vue';
import MyPagesOrder from './components/MyPagesOrder.vue';
import Dropdown from '../forms/Dropdown.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import * as myPages from '@/connectors/my-pages';

export default {
  extends: OptionsComponent,
  components: {
    MyPagesBack,
    MyPagesOrder,
    LoadingSpinner,
    Dropdown
  },
  emits: ['close-modal', 'back'],
  inject: {
    navigate: navigateKey,
  },
  data() {
    return {
      searchText: '',
      selectedSort: 'recent',
      orderCache: [],
      orders: [],
      requestPending: false,
      dropdownOpen: false,
      modalParent: null,
    };
  },
  computed: {
    selectedSortName() {
      switch (this.selectedSort) {
        case 'recent':
          return this.$globalTexts.mypages__sort_order_date || 'mypages__sort_order_date';

        case 'alpha':
          return this.$globalTexts.mypages__sort_order_id || 'mypages__sort_order_id';

        default:
          return this.$globalTexts.mypages__sort_order_date || 'mypages__sort_order_date';
      }
    },
    sortOptions() {
      return [
        {
          value: 'recent',
          text: this.$globalTexts?.mypages__sort_order_date || 'mypages__sort_order_date',
        },
        {
          value: 'alpha',
          text: this.$globalTexts?.mypages__sort_order_id || 'mypages__sort_order_id',
        },
      ];
    },
    isRecentSortSelected() {
      return this.selectedSort === 'recent';
    },
    isAlphaSortSelected() {
      return this.selectedSort === 'alpha';
    },
    orderDetailsPageUrl() {
      const url = this.$settings?.orderdetails?.cached_url || '';
      return url ? `/${url}` : '';
    },
  },
  watch: {
    searchText() {
      this.filterAndSortOrders();
    },
    selectedSort() {
      this.filterAndSortOrders();
    },
    $currentPageKey() {
      this.closeModal();
    },
  },
  methods: {
    closeDropdown() {
      this.dropdownOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    async fetchMyOrders() {
      const requestArgs = this.buildCommandArgs();

      const response = await this.executeCommand(myPages.commands.fetchMyOrders, requestArgs);

      return response;
    },
    filterAndSortOrders() {
      let result = this.orderCache || [];

      if (!Array.isArray(result)) {
        result = Object.values(result);
      }

      if (this.searchText) {
        result = (result || [])?.filter((r) => r.number.includes(this.searchText)) || [];
      }

      switch (this.selectedSort) {
        case 'recent':
          result = (result || [])?.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0)) || [];
          break;

        case 'alpha':
          result = (result || [])?.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0),
            ) || [];
          break;
      }

      this.orders = result;
    },
    openOrder(order) {
      if (order.isInvoice) {
        this.navigate(`${this.orderDetailsPageUrl}?invoiceNo=${order.number}`);
      } else {
        this.navigate(`${this.orderDetailsPageUrl}?orderNo=${order.number}`);
      }
    },
    buildCommandArgs() {
      return {
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          '',
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    },
  },
  async mounted() {
    this.modalParent = document.querySelector('.drawer--shown .drawer__container');
    this.orderCache = await this.fetchMyOrders();
    this.filterAndSortOrders();
  },
};
</script>

<template>
  <div class="my-pages-orders">
    <my-pages-back @back="onBack()" @exit="closeModal()" />
    <div class="my-pages-orders__header">
      <div class="my-pages-orders__header-title">{{ $globalTexts.mypages__orders || 'mypages__orders' }}</div>

      <div class="my-pages-orders__header-search-wrapper">
        <input
          :disabled="requestPending"
          v-model="searchText"
          class="my-pages-orders__header-search"
          :placeholder="$globalTexts.global__label_search"
        />
        <img
          class="my-pages-orders__header-search-icon"
          src="../../static/icons/search-icon.svg"
          @click="filterAndSortOrders()"
        />
      </div>

      <div class="my-pages-orders__header-sort">
        <span>{{ $globalTexts.global__sort_by || 'global__sort_by' }}</span>

        <div class="my-pages-orders__sort-wrapper">
          <Dropdown
            v-model="selectedSort"
            :options="sortOptions"
            ref="ordersDropdown"
            :disabled="requestPending"
            :modal-parent="modalParent"
          />
        </div>
      </div>
    </div>

    <loading-spinner
      v-if="requestPending"
      class="my-pages-orders__loader"
      :text="$globalTexts.global__loading || 'global__loading'"
    />

    <div v-if="!requestPending" class="my-pages-orders__list">
      <div class="my-pages-orders__list-order" v-for="order in orders" :key="order.number">
        <my-pages-order :order="order" @open="openOrder" />
      </div>
    </div>
  </div>
</template>

<style>
.my-pages-orders {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.my-pages-orders__header {
  padding: 20px 10px 10px;
}

.my-pages-orders__header-title {
  font-weight: bold;
  font-size: var(--size-m);
  line-height: 24px;
  margin-bottom: 10px;
}

.my-pages-orders__header-search-wrapper {
  position: relative;
}

.my-pages-orders__header-search-icon {
  position: absolute;
  top: 10px;
  height: 20px;
  right: 14px;
  cursor: pointer;
}

.my-pages-orders__header-search {
  border: 1px solid var(--color-neutral-50);
  width: 100%;
  height: 40px;
  padding: 0 14px;
  border-radius: 2px;
  outline: none;
}

.my-pages-orders .dropdown__selected-value,
.my-pages-orders .dropdown__option {
  line-height: 18px;
  font-size: var(--size-m);
  font-weight: 400;
  color: var(--color-main-90);
}

.my-pages-orders__header-search:focus {
  border-color: var(--color-feedback-info);
}

.my-pages-orders__header-search::placeholder {
  color: var(--color-neutral-60);
}

.my-pages-orders__header-search:-ms-input-placeholder {
  color: var(--color-neutral-60);
}

.my-pages-orders__header-search::-ms-input-placeholder {
  color: var(--color-neutral-60);
}

.my-pages-orders__sort-wrapper {
  position: relative;
  user-select: none;
  cursor: default;
  margin-top: 6px;
}

.my-pages-orders__sort {
  display: flex;
  align-items: center;
}

.my-pages-orders__sort-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.my-pages-orders__sort-icon--up {
  transform: rotate(180deg);
}

.my-pages-orders__sort-dropdown {
  top: calc(100% - 1px);
  position: absolute;
  background: var(--color-neutral-00);
  left: 0;
  right: 0;
  border-radius: 2px;
  border-color: var(--color-cool-gray);
  border-width: 1px;
  border-style: solid;
  line-height: var(--size-m);
  color: var(--color-neutral-100);
  font-size: var(--size-m);
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);
}

.my-pages-orders__sort-option {
  padding: 10px 15px;
  border-top: 1px solid var(--color-neutral-50);
}

.my-pages-orders__sort-option:first-child {
  border: none;
}

.my-pages-orders__sort-option:hover {
  background: var(--color-neutral-15);
}

.my-pages-orders__header-sort {
  margin-top: 1rem;
  color: var(--color-text-black);
  font-size: var(--size-xxs);
}

.my-pages-orders__loader {
  padding: 2rem 3rem;
}

.my-pages-orders__list {
  display: flex;
  flex-direction: column;
  padding: 0 10px 25px;
  overflow-y: auto;
  overflow-y: overlay;
}

.my-pages-orders__list-order {
  padding-bottom: 20px;
}

@media (--phone) {
  .my-pages-orders__header {
    padding: 30px 30px 20px;
  }

  .my-pages-orders__header-title {
    margin-bottom: 10px;
  }

  .my-pages-orders__list {
    padding: 0 30px 51px;
  }
}
</style>
